html,body{
    height: 100%;
}

body{
  background: var(--gray5);
  font-family: var(--font-family1);
  line-height: 1.42857143;
  font-size: 14px;
}
label{
  color: var(--gray2);
}
.input_bx1:focus{
  outline: unset;
}
ul.master_color li::marker {
  color: var(--mastercolor);
}
.app_logo{
  max-width: var(--logo-size);
}
.avatar-xs{
  height: 2rem;
    width: 2rem;
}
.uderline{
text-decoration: none;
}


/* START CUSTOM TAB TYPE 01 */
.nav_tab_t1.nav-tabs{
  border-bottom: transparent;
}
.nav_tab_t1.nav-tabs .nav-item{
  border:unset;
  flex-grow: 1;
}
.nav_tab_t1.nav-tabs .nav-link{
  color: var(--gray2);
  font-weight: 700;    
  padding: 15px 20px 12px 20px;
  text-align: center;
  font-size: 14px;
}
.nav_tab_t1.nav-tabs .nav-link:focus, .nav_tab_t1.nav-tabs .nav-link:hover{
  border-color:transparent;
  color: var(--mastercolor);
}
.nav_tab_t1.nav-tabs .nav-item.show .nav-link, .nav_tab_t1.nav-tabs .nav-link.active {
  border-color: transparent;
  color: var(--mastercolor);
  border-bottom: 3px solid var(--mastercolor);
}
.nav_tab_t1.nav-tabs .nav-link.active:focus, .nav_tab_t1.nav-tabs .nav-link.active:hover{
  border-color: transparent;
  color: var(--mastercolor);
  border-bottom: 3px solid var(--mastercolor);
}
/* END CUSTOM TAB TYPE 01 */
.nav_tab_t1.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: var(--white);
  background-color: var(--mastercolor);
}
.nav-link:focus, .nav-link:hover{
  color: var(--mastercolor);
}
.nav_tab_t1.nav-pills .nav-link {
  color: #ADADAD;
  font-weight: 600;
  border-radius: 2rem;
  padding: 0.4rem 1rem;
}

.nav_tab_t2.nav-tabs .nav-link {
  color: #ADADAD;
  font-weight: 600;
  padding: 15px 20px 12px 20px;
  text-align: center;
  font-size: 16px;
}
.nav_tab_t2.nav-tabs .nav-link .tab_counts{
  background-color: var(--white);
  color: #ADADAD;
  border: 1px solid #ADADAD;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
    position: relative;
}
.nav_tab_t2.nav-tabs .nav-link .tab_counts.completed{
  background-color: var(--green1);
  color: var(--white);
  border: 1px solid var(--green1);
}
.nav_tab_t2.nav-tabs .nav-link:focus, .nav_tab_t2.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav_tab_t2.nav-tabs{
  border: unset;
}
.nav_tab_t2.nav-tabs .nav-item.show .nav-link, .nav_tab_t2.nav-tabs .nav-link.active{
  color: var(--mastercolor);
    background-color: transparent;
    border-color: transparent;
}
.nav_tab_t2.nav-tabs .nav-item.show .nav-link .tab_counts, .nav_tab_t2.nav-tabs .nav-link.active .tab_counts{
  background-color: var(--mastercolor);
  color: #fff;
  border: 1px solid var(--mastercolor);
}
.nav_bar_outer{
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.nav_tab_t2_line{
  position: absolute;
    top: 28px;
    left: 12%;
    background-color: var(--masterlight);
    height: 1px;
    width: 80%;
}

.nav_tab_t3.nav-tabs .nav-link {
  color: #949494;
    background: #dbdbdb;
    font-weight: 500;
    padding: 8px 20px 8px 20px;
    text-align: center;
    font-size: 12px;
    border-radius: 45px;
}
.nav_tab_t3.nav-tabs .nav-link .tab_counts{
  background-color: var(--white);
  color: #ADADAD;
  border: 1px solid #ADADAD;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
    position: relative;
}
.nav_tab_t3.nav-tabs .nav-link .tab_counts.completed{
  background-color: var(--green1);
  color: var(--white);
  border: 1px solid var(--green1);
}
.nav_tab_t3.nav-tabs .nav-link:focus, .nav_tab_t3.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav_tab_t3.nav-tabs{
  border: unset;
}
.nav_tab_t3.nav-tabs .nav-item.show .nav-link, .nav_tab_t3.nav-tabs .nav-link.active{
  color: var(--white);
    background-color:  var(--mastercolor);
    border-color: transparent;
}
.nav_tab_t3.nav-tabs .nav-item.show .nav-link .tab_counts, .nav_tab_t3.nav-tabs .nav-link.active .tab_counts{
  background-color: var(--mastercolor);
  color: #fff;
  border: 1px solid var(--mastercolor);
}
.tb_hide_search .dataTables_filter{
  display: none;
}
.tb_hide_info .dataTables_info{
  display: none;
}
.table_type01 table{
  padding: 0!important;
  padding-bottom: 5px!important;
  border: unset!important;
  border-radius: 5px!important;
}
.table_type01 table.dataTable th, .table_type01 table.dataTable td{
  vertical-align: middle;
}
.table_type01 table.dataTable thead th{
  border: unset;
  border-bottom: 2px solid var(--gray1);
  background-color: #f8f9fa;
  text-transform:none;
  font-size: 14px;
  padding:0.75rem 1rem 0.75rem 0.75rem;
}
.table_type01 table.dataTable thead th:first-child{
  border-top-left-radius: 5px !important;
}
.table_type01 table.dataTable thead th:last-child{
  border-top-right-radius: 5px !important;
}
.table_type01 table.dataTable tbody td{
  border: unset;
  padding: 0.75rem;
  font-size: 12px;
  border-bottom: 1px solid  var(--gray1);
}
.table_type01 table.dataTable tbody tr:last-child td{
  border-bottom: unset;
}
.table_type01 .dataTables_paginate{
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}
.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current, .table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  color: white !important;
  background: var(--mastercolor);
  border: 1px solid  var(--mastercolor);
  border-radius: 4px;
}
.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
  background: var(--mastercolor);
  border: 1px solid  var(--mastercolor);
  opacity: 0.7;
}
.table_type01 table.dataTable tbody td a{
 color: var(--mastercolor);
}

.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button{
  line-height: 100%;
  padding: 5px 8px;
}
.table_type02.table>:not(:first-child){
  border-top: 1px solid #dde2e6;
}
table.valign_middle th, table.valign_middle td {
  vertical-align: middle;
}
.form_bx_stack label{
 margin-bottom: 5px;
}
.form_bx_stack .data{

}
.form_bx_inline{
  display: flex;
}
.form_bx_inline label{
  margin-bottom: 0;
  padding-top: 6px;
}
.form_bx_inline .data{
  margin-left: 5px;
}
.input_withicon{position: relative;}
.input_withicon.start .with_text{
  padding-left: 35px !important;
}
.input_withicon.start .with_icon{
  padding-left: 35px !important;
}
.input_withicon.end .with_icon{
  padding-right: 40px !important;
}
.input_withicon .icn{
  position: absolute;
    top: 0;
    height: 36px;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
}
.input_withicon.start .icn{
    left: 2px;
}
.input_withicon.end .icn{
    right: 10px;
}


.select_dropdown .with_icon_end{
  padding-right: 30px;
}
.select_dropdown .icn_end{
  position: absolute;
  right: 2px;
  top: 2px;
}
.select2-container{
  z-index: 1055;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--mastercolor)!important;
  color: white;
}
.select2-container--open .select2-dropdown--below{
  border: unset;
  padding: 5px 0px;
  margin-top: 1px;
  box-shadow: 1px 1px 10px 2px rgb(204 204 204 / 36%);
}
.select2-results__option {
  border-top: 1px solid var(--gray1);
}
.select2-search--dropdown .select2-search__field:focus-within{
  outline: unset;
}
.select2-container--default .select2-search--dropdown .select2-search__field{
  border: 1px solid var(--gray)!important;
  border-radius: 3px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid var(--gray)!important;
}
.select2-container--default .select2-selection--multiple{
  border: 1px solid var(--gray)!important;
}
.select2-container--default .select2-search--inline .select2-search__field {
  padding: 0 10px;
}
.select_bx1{
  position: relative;
}
.select_bx1 .select2-container{
  z-index: 1;
  width: 100% !important;
}
.select_bx1 .select2-container .select2-selection--single .select2-selection__rendered{
  line-height: 35px;
}
.select_bx1.with_left_icon .select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 30px;
}
.select_bx1 .select2-container .select2-selection--single .select2-selection__rendered{
  padding-right: 30px;
}
.select_bx1.select_master_line .select2-container .select2-selection--single .select2-selection__rendered{
  color: var(--mastercolor);
}
.select_bx1 .select2-container--default .select2-selection--single{
  border:unset;
  background-color: transparent;
  height: 36px;
}
.select_bx1.select_with_border .select2-container--default .select2-selection--single{
  border:1px solid var(--gray)!important;
}
.select_bx1.select_with_border.select_master_line .select2-container--default .select2-selection--single{
  border:1px solid var(--mastercolor)!important;
}

.select_bx1 .select2-container--default .select2-search--inline .select2-search__field{
  padding: 1px 12px;
}
.select_bx1 .select2-container--default .select2-selection--multiple .select2-selection__rendered{
  padding: 1px 12px;
}
.select_bx1 .select2-container .select2-selection--multiple{
  min-height: 36px;
}
.select_bx1 .left_icn{
  position: absolute;
  left: 2px;
  top: 2px;
}
.select_bx1 .right_icn{
  position: absolute;
  right: 2px;
  top: 3px;
  color: var(--gray2);
}
.select_bx1.select_master_line .right_icn{
  color: var(--mastercolor);
}
.select_bx1 .select2-container.select2-container--open + .right_icn{
  transform: rotate(180deg);
  color: var(--mastercolor);
}
.select_bx1 .select2-container--default .select2-selection--single .select2-selection__arrow{
  display: none;
}



.custom_scroll1::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.custom_scroll1::-webkit-scrollbar-track {
  border-radius: 10px;
  background:#F0F0F0;
}
.custom_scroll1::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 10px;
}
.custom_scroll1::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8; 
}


.form-check-input[type=checkbox] {
  border-radius: 0.15em;
}
input[type="date"]{
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}



/* Month Picker */
.ui-widget-header .ui-icon{
  left: 5px;
    top: 5px;
    position: relative;
}
.ui-state-disabled{
  pointer-events: none;
}


.px_loader{
  z-index: 1;
}
.px_loader_content {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px;
}
.px_loader_content div {
  position: absolute;
  border: 4px solid var(--mastercolor);
  opacity: 1;
  border-radius: 50%;
  animation: loader_anim 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.px_loader_content div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loader_anim {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Loader */

.circle-loader-shape {
  animation: a 1.5s linear infinite;
    height: 70px;
    width: 70px;
}
.circle-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: b 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes a {
  to {
      transform: rotate(1turn);
  }
}
@keyframes b {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
  }
  to {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
  }
}

.text_master .form-check-label{
  color: var(--mastercolor)!important;
}


.disable_div{
  opacity: 0.5;
  pointer-events: none;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: var(--mastercolor);
}

.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
  color: var(--gray2);
}
.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.15rem var(--mastercolor25p);
}






.form-control{
  border: 1px solid var(--gray);
  font-size: 14px;
    padding: 8px 15px;
}
.form-control:hover, .form-control:focus{
box-shadow: unset;
/* border: 1px solid var(--mastercolor2); */
border: 1px solid var(--gray);
}
.form-control::placeholder{
  color: var(--placeholder);
}
/* MODAL  */
.modal-content {
  border: 1px solid #f6f6f6;
}
.range_slider {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0.85;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.range_slider:hover {
  opacity: 1;
}

.range_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.range_slider::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.range_slider::-webkit-slider-thumb:active {
  background: var(--mastercolor);
  border: unset;
  box-shadow: 0px 0px 0px 4px rgb(1 40 89 / 16%);
  cursor: pointer;
}
.range_slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.NotifMenu{
  width: 300px;
  z-index: 2 !important;
}
.NotifMenuItem{

  max-height: 300px;
}
.NotifMenu.dropdown-menu
{
    z-index: unset;
    background-color: unset;
    background-clip: unset;
    border: none;
    border-radius: unset;
}
.arco-spin{
  height: 100%;
  width: 100%;
}
.arco-spin .arco-spin-children{
  height: 100%;
}
.arco-pagination-total-text{
  white-space: nowrap;
}

.chatbox_head{
  height: calc(100svh - 178px);
}

.border_radius_chat{
  border-radius: 15px 15px 0 15px;
}

.border_radius_chat_2{
  border-radius: 15px 15px 15px 0;
}

.send_message_icon{
  transform: rotate(45deg);
}

.popUpmyAccount{
  min-height: auto;
  max-height: 300px;
}
.height_t1{
  /* min-width: 900px; */
  /* min-width: 1200px; */
  max-height: calc(100svh - 190px);
}

.poxyModal{
  overflow: auto;
}

/* ---------- START ARCO COMPONENTS ----------- */
.arco_select .arco-select{
  width: 100%  !important;
}
.arco_select .arco-select .arco-select-view {
  background-color: var(--white)!important;
  height: 35.6px;
  border: 1px solid var(--gray);
  border-radius: 0.25rem!important;
}
.arco_select .arco-select-single .arco-select-view-value {
  line-height: 2.9;
  font-size: 12px;
}
.arco_select .arco-select-size-default.arco-select-single input {
  font-size: 12px;
}

.arco_select .arco-select-size-default.arco-select-single input::placeholder {
  color: #A1BAD2;
}

.arco_table1 .arco-table-content-inner thead .arco-table-th{
 background-color: var(--mastercolor);
 color: var(--white);
}
.arco_table1 .arco-table-content-scroll{
 overflow: unset;
}
.arco-trigger{
  z-index: 1055;

}
.arco-message-wrapper {
  z-index: 1056;
}
.arco_rangepicker .arco-picker.arco-picker-range{
  width: 100%  !important;
  background-color: var(--white)!important;
  height: 35.6px;
  border: 1px solid var(--gray);
  border-radius: 0.25rem!important;
}
.arco_rangepicker .arco-picker-size-default input {
  font-size: 12px;
}
.arco-picker-cell-selected .arco-picker-date-value, .arco-picker-cell-selected:hover .arco-picker-date-value {
  background-color: var(--mastercolor);
}

.arco_slider .arco-slider-bar{
  background-color: var(--mastercolor);
}
.arco_slider .arco-slider-button::after {
  content: '';
  border: 2px solid  var(--mastercolor);

}

.meterialUI .css-15gm0yv-MuiSlider-root {
color: var(--mastercolor) !important;
}

.arco_datepicker .arco-picker{
  width: 100%  !important;
  background-color: var(--white)!important;
  height: 35.6px;
  border: 1px solid var(--gray);
  border-radius: 0.25rem!important;
}
.arco_datepicker .arco-picker-size-default input {
  font-size: 12px;
}
.arco_datepicker .arco-picker-size-default input::placeholder {
  color: var(--placeholder);
}


input[name='button-radio-group']:focus-visible + .arco-btn {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}


.custom-radio-card {
  padding: 10px 16px;
  box-sizing: border-box;
}

.arco-radio-group .arco-radio {
  margin-right: 0;
  width: 100%;
}

.custom-radio-card-mask-dot {
  width: 8px;
  height: 8px;
}

input[name='card-radio-group']:focus-visible + .custom-radio-card {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}

.custom-radio-card-checked {
  background-color: var(--color-primary-light-1);
}

.custom-radio-card:hover .custom-radio-card-title,
.custom-radio-card-checked .custom-radio-card-title {
  color: rgb(var(--primary-6));
}

.custom-radio-card-checked .custom-radio-card-mask-dot {
  background-color: rgb(var(--primary-6));
}

.arco_inputsearch .arco-input-search{
  border: 1px solid var(--gray);
    border-radius: 0.25rem!important;
    height: 35.6px;
}
.arco_inputsearch .arco-input-inner-wrapper {
  background-color: var(--white)!important;
}
.arco_inputsearch .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: transparent;
  box-shadow: unset;
}
.arco_inputsearch .arco-btn-primary:not(.arco-btn-disabled) {
  background-color: var(--mastercolor);
  transition: all 0.3s;
}

.arco_inputsearch .arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  background-color: var(--mastercolor);
  opacity: 0.8;
}

.arco_inputsearch .arco-input-inner-wrapper .arco-input {
 font-size: 12px;
}





.arco_modal_wrap.arco-modal-wrapper{
  z-index: 1056;
  padding: 10px;
}
.arco_modal_wrap .arco-modal-simple {
  padding: 20px 15px;
  max-width: 464px;
  width: 100%;
}
.arco_modal_wrap .arco-modal-simple .arco-modal-header {
  margin-bottom: 10px;
}
.arco_modal_wrap .arco-modal-simple .arco-modal-content {
  text-align: center;
}
.arco_modal_wrap .arco-modal-simple .arco-modal-footer {
  margin-top: 10px;
}
.arco-modal-mask{
  z-index: 1055;
}

.arco_modal_wrap .arco-btn-status-master{
  background-color: var(--mastercolor)!important;
  color: #fff;
  border: 1px solid var(--mastercolor);
  width: 100%;
  max-width: 100px;
  border-radius: 0.25rem!important;
}
.arco_modal_wrap .arco-btn-status-master:focus, .arco_modal_wrap .arco-btn-status-master:hover{
  background-color: var(--mastercolor)!important;
  color: #fff;
  -webkit-box-shadow: 0 0 0 2px var(--mastercolor25p);
  box-shadow: 0 0 0 2px var(--mastercolor25p);
}
/* ---------- END ARCO COMPONENTS ----------- */



.proxydoc .device-container {
  position: unset;
}

.proxydoc page{
  padding: 19px !important;
}
.loginFooter_links{
  font-size: 10px;
}


/* ----------- RESPONSIVE SECTIONS --------------- */
@media (max-width:1200px){
  .height_t1{
    min-width: 900px; 
    min-width: 1300px; 
    /* max-height: calc(100svh - 190px); */
  }
}
@media (max-width: 992px){
  .captcheModal .modal-content{
    margin-bottom: 110px;
  } 
}
@media (max-width: 767px){
 
  .loginFooter_links{
    font-size: 10px;
  }
  .forgot_links{
    font-size: 12px;
  }
  .arco-table-cell{
    word-break:unset
  }
}



@media (max-width: 550px){

  .arco-trigger.arco-trigger-position-bl{
    max-width: calc(100% - 50px);
  
  }
  .arco-picker-container, .arco-picker-range-container{
    width: 100%;
    overflow: auto;
  }  
  .captcheModal .modal-content{
    margin-bottom: 0;
  } 
  
}


