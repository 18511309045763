
  .logo{
    max-width: 100px;
  }

  
  /* SIDE BAR STYLES */

  .sidebar_wrapper .css-1wvake5.ps-broken {
    position: unset;
    width: 0;
    min-width: 0;
    overflow: hidden;
    border: unset;
  }
  .sidebar_wrapper .css-8i0j0q{
    display: none;
  }
  .sidebar_wrapper .css-1wvake5.ps-broken.ps-toggled {
    position: unset;
    width: 250px;
    /* min-width: 250px; */
  }
  .sidebar_wrapper .css-dip3t8 {
    background-color: unset;
    box-shadow: unset;
    padding-right: 3px;
    /* background: linear-gradient(338deg, #002760 0%, #7DB218 100%);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05); */
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */
  }
  .sidebar_wrapper .css-1wvake5.ps-broken.ps-toggled .sidebar_title{
    width: auto;
  }
  .sidebar_wrapper .css-1wvake5.ps-broken .sidebar_title{
    width: 0;
    overflow: hidden;
  }
  .sidebar_wrapper .css-nwj8xd >.ps-menu-button {
    padding-right: 15px;
    padding-left: 20px;
}
.sidebar_wrapper .ps-menu-button.ps-active {
  background-color: rgb(255 255 255 / 41%);
}
  .sidebar_wrapper .ps-menu-button.ps-active:hover {
    background-color: rgb(255 255 255 / 41%);
  }
  .sidebar_wrapper .css-nwj8xd >.ps-menu-button:hover{
    background-color: rgb(243 243 243 / 18%);
  }
  .sidebar_wrapper .css-wx7wi4 {
    width: 20px;
    min-width: 20px;
    margin-right: 10px;
    color: var(--white);
  }
  .sidebar_wrapper .css-12w9als {
    font-size: 14px;
    color: var(--white);
  }
  .sidebar_wrapper .fYFzBN svg {
    color: unset; 
    fill: #fff;
  }
  .sidebar_wrapper .dNjirN svg {
    color: #fff!important;
    fill: #fff!important;
  }
  .sidebar_wrapper .css-vj11vy.ps-menu-root {
    /* height: calc(100dvh - 183px); */
    height: calc(100svh - 150px);
    overflow: auto;
  }
  .sidebar_wrapper .css-vj11vy.ps-menu-root::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .sidebar_wrapper .css-vj11vy.ps-menu-root::-webkit-scrollbar-track {
    background: rgb(255 255 255 / 15%);
    /* border: 1px solid rgb(255 255 255 / 15%); */
    border-radius: 10px;
  }
  .sidebar_wrapper .css-vj11vy.ps-menu-root::-webkit-scrollbar-thumb {
    background: rgb(255 255 255 / 30%);
    border-radius: 10px;
  }
  .sidebar_wrapper .css-vj11vy.ps-menu-root::-webkit-scrollbar-thumb:hover {
    background: rgb(255 255 255 / 35%); 
  }
  
.main-content-area{
  width: 100%;
  transition: width 300ms;
}
.main-content-area.active{
  width: calc(100% - 250px);
}





  /* ----------- RESPONSIVE SECTIONS --------------- */
  @media (max-width: 992px){
    .sidebar_wrapper{
      position: fixed !important;
      height: 100%;
    }
    /* .sidebar_wrapper .css-vj11vy.ps-menu-root {
      height: calc(100dvh - 60px);
    } */
    .sidebar_toggle{
      position: relative;
    }
    .main-content-area.active{
      width: 100%;
    }

    .sidebar_wrapper .css-1wvake5.ps-broken {
      position: unset;
      width: 250px;
    }
    .sidebar_wrapper .css-1wvake5.ps-broken.ps-toggled {
      position: unset;
      width: 0;
      min-width: 0;
      overflow: hidden;
      border: unset;
    }
  }
  
  @media (max-width: 767px){

  
  }
  
  @media (max-width: 550px){

  }