.logo_prop{
  max-width: 120px;
}
.login_screen{
  position: relative;
}
.login_screen::before{
  position: absolute;
  content: "";
  background-image: url(../../assets/images/login_topbg.jpg);
  left: 0;
  top: 0;
  width: 100%;
  height: 35vh;
}
.login_screen::after{
  position: absolute;
  content: "";
  background-image: url(../../assets/images/login_bottombg.jpg);
  background-position: bottom;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 65vh;
  opacity: 0.2;
}


.login_left  {
  /* background-image: url(../../assets/images/bg_img1.jpg); */
  background-position: center center;
  background-size: cover;
  position: relative;
  width: 100%;
}
.login_right{
  width: 100%;
  margin: auto 0;
}
.login_right.h-100{
  height: unset!important;

}

/* .login_left::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--masterdark3);
  opacity: 0.9;
  width: 100%;
  height: 100%;
} */
.loginbx{
  max-width: 440px;
  /* height: calc(100dvh - 75px); */
}

.left_content{
  margin-top: calc(35vh - 35px)!important;
}

.regMob{
  height: calc(100vh - 180px);
}

@media (min-width: 768px){
  .loginbx{
    height: calc(100svh - 100px);
    max-width: 1000px;
  }
  .login_left  {
    width: 55%;
  }
  .login_right{
    width: 45%;
    margin: 0;
  }
  

}
